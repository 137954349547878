import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { HeroSection } from "@shared";
import * as C from "@components/CareersPage";
import { TCareers } from "@types";

const selectorCurrentVacancies = "current-vacancies";

const CareersPage = ({ data }: TCareers) => {
  const { seo, hero } = data.strapiCareersPage;

  return (
    <Layout seo={seo}>
      <HeroSection
        bgHero={{ bgMobile: hero.bgMobile, bgDesktop: hero.bgDesktop }}
        title={hero.title}
        description={hero.description}
        buttonScroll={{
          scrollTo: selectorCurrentVacancies,
          text: "BROWSE OUR JOB OPPORTUNITIES",
        }}
      />
      <C.Content />
      <C.PerksAndBenefits />
      <C.CurrentVacancies selectorId={selectorCurrentVacancies} />
    </Layout>
  );
};

export default CareersPage;

export const query = graphql`
  {
    strapiCareersPage {
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicUrl
          }
        }
      }
      hero {
        title
        description
        bgMobile {
          localFile {
            publicUrl
          }
        }
        bgDesktop {
          localFile {
            publicUrl
          }
        }
      }
    }
  }
`;
